export const replaceWithCorrectTranslation = (module, blocksJSON) => {
	const moduleName = module.__typename
	if(!module.attributesJSON) return module
	const attributesJSONparsed = JSON.parse(module.attributesJSON)
	const matchedJSONBlock = blocksJSON.find((parsed) => parsed.attributes.id === attributesJSONparsed.id).attributes.data
	const trimInternalUrl = (text) => {
		try {
			return text.includes('https://source.davidundmartindev.de') ? text.split('https://source.davidundmartindev.de')[1] : text
		} catch (error) {
			return null
		}
	}

	switch (moduleName) {
		case 'WpAcfBigtextBlock':
			
			if (!!module.moduleBigtext?.overline) module.moduleBigtext.overline = matchedJSONBlock.overline
			if (!!module.moduleBigtext?.headline) module.moduleBigtext.headline = matchedJSONBlock.headline
			if (!!module.moduleBigtext?.text) module.moduleBigtext.text = matchedJSONBlock.text?.replace(/\r\n/g, "<br>")
			if (!!module.moduleBigtext?.decorationLink?.url) module.moduleBigtext.decorationLink.url = trimInternalUrl(matchedJSONBlock[`decoration_link`].url)
			return module
		case 'WpAcfCollageBlock':
			if (!!module.moduleCollage?.texts) module.moduleCollage.texts = matchedJSONBlock.texts
			return module
		case 'WpAcfLocationsBlock':
			if (!!module.moduleLocations?.headline) module.moduleLocations.headline = matchedJSONBlock.headline
			!!module.moduleLocations?.places &&
				module.moduleLocations?.places.forEach((item, index) => {
					if (!!item.title) item.title = matchedJSONBlock[`places_${index}_title`]
					if (!!item.description) item.description = matchedJSONBlock[`places_${index}_description`]?.replace(/\r\n/g, "<br>")
					if (!!item.link?.url) item.link.url = trimInternalUrl(matchedJSONBlock[`places_${index}_link`].url)
				})
			return module
		case 'WpAcfNewstickerBlock':
				!!module.moduleNewsticker?.items &&
				module.moduleNewsticker?.items.forEach((item, index) => {
					if (!!item.text) item.text = matchedJSONBlock[`items_${index}_text`]
					if (!!item.link?.url) item.link.url = trimInternalUrl(matchedJSONBlock[`items_${index}_link`].url)
				})
			return module
		case 'WpAcfPeopleBlock':
			// console.log(module.modulePeople)
			!!module.modulePeople?.cards &&
				module.modulePeople?.cards.forEach((item, index) => {
					if(!item.image) {
						fetch("https://source.davidundmartindev.de/wp-json/wp/v2/media/" + matchedJSONBlock[`cards_${index}_image`])
							.then(response => response.json())
							.then(data => {
								item.image = data.media_details?.sizes?.medium_large?.source_url
								item['imageWP'] = true
							})
							.catch(error => {
								console.error("Error:", error);
							});
						
					}
					if (!!item.headline) item.headline = matchedJSONBlock[`cards_${index}_headline`]
					if (!!item.text) item.text = matchedJSONBlock[`cards_${index}_text`]?.replace(/\r\n/g, "<br>")
				})
			return module
		case 'WpAcfTextimageBlock':
			if (!!module.moduleTextimage?.overline) module.moduleTextimage.overline = matchedJSONBlock.overline
			if (!!module.moduleTextimage?.headline) module.moduleTextimage.headline = matchedJSONBlock.headline
			if (!!module.moduleTextimage?.text) module.moduleTextimage.text = matchedJSONBlock.text
			return module
		case 'WpAcfTextschlangeBlock':
			if (!!module.moduleTextschlange?.svgtext) module.moduleTextschlange.svgtext = matchedJSONBlock.svgtext
			return module
	}
}
