import React, { useState } from "react"
import tw from "twin.macro"
import { graphql } from "gatsby"
import WpAcfLocationsBlock from "@modules/WpAcfLocationsBlock"
import NoteBubble from "@elements/NoteBubble"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import { Footer } from "@components/root/Footer"
import BasicContent from "@components/modules/BasicContent"
import ScrollSmoother from "gsap/ScrollSmoother"
import { SEO } from "@root/Seo"
import axios from "axios"
import { replaceWithCorrectTranslation } from "../utils/replaceWithCorrectTranslation"

const Main = tw.div`mx-auto bg-black flex flex-col`

export default function PageTemplate({ data, location }) {
  gsap.registerPlugin(ScrollTrigger, ScrollSmoother)
  const mainRef = React.useRef()
  const main = data.main
  const blocksJSON = JSON.parse(main.blocksJSON)
  const noteBubble = main.asideNotebubble?.notebubble
  const frontpage = data.frontpage
  const locationsData = () => {
    let __blocksJSON = main.locale?.locale === "de_DE" ? JSON.parse(frontpage.blocksJSON) : JSON.parse(frontpage?.translated[0]?.blocksJSON)
    let __locationsData =
      main.locale?.locale === "de_DE" ? frontpage?.blocks?.filter(block => block.__typename === "WpAcfLocationsBlock")[0] : frontpage?.translated[0]?.blocks?.filter(block => block.__typename === "WpAcfLocationsBlock")[0]
    replaceWithCorrectTranslation(__locationsData, __blocksJSON)

    return <WpAcfLocationsBlock data={__locationsData} key={`WpAcfLocationsBlock-${main.slug}`} />
  }
  React.useLayoutEffect(() => {
    ScrollTrigger.refresh()
    let smoother
    if (ScrollTrigger.isTouch !== 1) {
      smoother = ScrollSmoother.create()
    }
    return () => {
      if (ScrollTrigger.isTouch !== 1) smoother.kill()
    }
  })

  const [isPasswordProtected, setIsPasswordProtected] = useState(main.content === null)
  const [isPasswordValid, setIsPasswordValid] = useState(false)
  const [passwordInput, setPasswordInput] = useState("")

  const checkPassword = async () => {
    try {
      const response = await axios.get(`https://source.davidundmartindev.de/wp-json/wp/v2/pages/${main.databaseId}`, {
        params: {
          password: passwordInput,
        },
      })
      if (response.status === 200) {
        setIsPasswordValid(true)
        setIsPasswordProtected(false)
      }
    } catch (error) {
      console.log({ error })
      setIsPasswordValid(false)
    }
  }

  return (
    <>
      <div id="smooth-wrapper">
        <div id="smooth-content">
          <Main ref={mainRef}>
            {isPasswordProtected ? (
              <div className="relative w-full py-spacing-250">
                <div className=" px-spacing-15 md:px-offset-xs 3xl:px-offset-master 4xl:px-spacing-75">
                  <label className="relative z-20 text-white text-20px/140 md:text-22px/140 lg:text-28px/140 2xl:text-32px/140 sm:max-w-[500px] lg:max-w-[650px] 2xl:w-full 2xl:max-w-[650px] 4xl:max-w-[900px]">
                    Please enter the password to view this page:
                  </label>
                  <div className="flex items-center gap-5 mt-2">
                    <input
                      className="px-3 py-2 border-2 border-white text-20px/115 xxs:text-24px/115 lg:text-20px/115 3xl:text-24px/115 4xl:text-32px/115"
                      type="password"
                      name="password"
                      value={passwordInput}
                      onChange={e => setPasswordInput(e.target.value)}
                    />
                    <button
                      className="px-3 py-2 text-black transition duration-300 ease-in-out border-2 opacity-100 bg-yellow border-yellow text-20px/115 xxs:text-24px/115 lg:text-20px/115 3xl:text-24px/115 4xl:text-32px/115 hover:bg-black hover:text-white"
                      onClick={checkPassword}
                    >
                      Unlock
                    </button>
                  </div>
                </div>
              </div>
            ) : isPasswordValid || !isPasswordProtected ? (
              <React.Suspense fallback={null}>
                {main.blocks?.map((module, index) => {
                  if (module.__typename.includes("WpCore")) return ""
                  replaceWithCorrectTranslation(module, blocksJSON)
                  const LoadableModuleBlock = React.lazy(() => import(`@modules/${module.__typename}`).catch(() => ({ default: () => <div className="text-white">{module.__typename} not prepared.</div> })))
                  return <LoadableModuleBlock data={module} location={location} key={`${module.__typename}-${index}`} locale={main.locale?.locale} />
                })}
                {main.template?.templateName === "Basic Template" && <BasicContent key="basic-content" data={{ content: main.content, title: main.title }} />}
                {!main.parentId && <>{!main.isFrontPage && !main.translated[0]?.isFrontPage && locationsData()}</>}
                <Footer key="page-footer" variant={"main"} />
              </React.Suspense>
            ) : (
              <div>Invalid password. Please try again.</div>
            )}
          </Main>
        </div>
      </div>
      {noteBubble.image && noteBubble.messages.length > 0 && <NoteBubble data={noteBubble} />}
    </>
  )
}

export const Head = ({ data }) => <SEO seoData={data.main.seo} />

export const query = graphql`
  query ($page: String) {
    main: wpPage(id: { eq: $page }) {
      title
      id
      uri
      content
      slug
      parentId
      databaseId
      isFrontPage
      asideNotebubble {
        ...AsideNoteBubble
      }
      locale {
        locale
      }
      template {
        templateName
      }
      translated {
        slug
        uri
        isFrontPage
      }
      translations {
        href
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      blocks {
        __typename
        ...AllBlocks
      }
      blocksJSON
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
    }
    frontpage: wpPage(isFrontPage: { eq: true }) {
      id
      blocks {
        __typename
        ...AllBlocks
      }
      blocksJSON
      translated {
        blocks {
          __typename
          ...AllBlocks
        }
        blocksJSON
      }
    }
  }
`
