import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { useStateContext } from "@context/stateContext"
import { AnimatePresence, LayoutGroup, motion } from "framer-motion"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"

const TextBubble = tw(motion.div)`max-w-[320px] md:max-w-none`
const CloseButton = tw(motion.button)`absolute right-0 bottom-full mb-3 h-6 w-6 rounded-full bg-white text-xs text-black`
const Message = styled(motion.div)`
  ${tw`relative z-10 px-5 py-2 bg-white`}

  a {
    ${tw`underline`}
  }
`

const NoteBubble: React.FC<Props> = ({ data }) => {
  const [visibility, setVisibility] = React.useState(false)
  const { setMouseInteracting } = useStateContext()

  React.useEffect(() => {
    let chatVisibilityTimeout
    chatVisibilityTimeout = setTimeout(() => {
      setVisibility(true)
    }, data.timeout * 1000)
    return () => {
      clearTimeout(chatVisibilityTimeout)
    }
  }, [])

  const bubbleVariants = {
    initial: {
      y: "100%",
    },
    animate: {
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      x: "100%",
      opacity: 0,
      transition: {
        type: "spring",
      },
    },
  }

  return (
    <AnimatePresence mode="wait">
      {visibility && (
        <motion.div variants={bubbleVariants} initial="initial" animate="animate" exit="exit" className="fixed bottom-spacing-15 right-spacing-15 z-[99] flex items-center justify-center">
          <div className="flex flex-col items-end justify-center md:flex-row md:items-center">
            <LayoutGroup>
              {data.link?.url && (
                <Link
                  to={data.link.url}
                  className="order-3 md:absolute bottom-0 flex flex-col items-end justify-center mr-3 w-max right-24 gap-y-2 md:min-h-[80px]"
                  onMouseEnter={() => setMouseInteracting(true)}
                  onMouseLeave={() => setMouseInteracting(false)}
                >
                  <Messages messages={data.messages} />
                </Link>
              )}
              {!data.link?.url && (
                <div className="order-3 md:absolute bottom-0 flex flex-col items-end justify-center mr-3 w-max right-24 gap-y-2 md:min-h-[80px]">
                  <Messages messages={data.messages} />
                </div>
              )}

              <motion.div layout className="w-20 h-20 mb-5 overflow-hidden text-white bg-black rounded-full shadow-lg md:mb-0">
                <CloseButton onClick={e => setVisibility(false)} onMouseEnter={() => setMouseInteracting(true)} onMouseLeave={() => setMouseInteracting(false)}>
                  {"✕"}
                </CloseButton>
                <GatsbyImage className="w-full h-full" image={getImage(data.image.localFile)} alt="" />
              </motion.div>
            </LayoutGroup>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

const textVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      type: "spring",
    },
  },
}

const Messages = ({ messages }) => {
  const [textBubbleAni1, setTextBuggleAni1] = React.useState(false)
  const [textBubbleAni2, setTextBubbleAni2] = React.useState(false)
  const { setMouseInteracting } = useStateContext()

  React.useEffect(() => {
    let textBubbleTimeout, textBubbleTimeout2
    if (messages[1]) {
      textBubbleTimeout = setTimeout(() => {
        setTextBuggleAni1(true)
      }, messages[1].timeout * 1000)
    }
    if (messages[2]) {
      textBubbleTimeout2 = setTimeout(() => {
        setTextBubbleAni2(true)
      }, (messages[1].timeout + messages[2].timeout) * 1000)
    }
    return () => {
      clearTimeout(textBubbleTimeout)
      clearTimeout(textBubbleTimeout2)
    }
  }, [])

  const setMouseOvers = e => {
    if (e.target.tagName === "A") {
      setMouseInteracting(true)
    } else {
      setMouseInteracting(false)
    }
  }

  return (
    <>
      {messages.map((message, index) => {
        if (index === 0) {
          return (
            <TextBubble layout key={`textb-${index}`} variants={textVariants} initial="initial" animate="animate" className="relative shadow-lg">
              <Message onMouseOver={e => setMouseOvers(e)} onMouseLeave={() => setMouseInteracting(false)}>
                {parse(message.text)}
              </Message>
              <div className="absolute z-0 w-full h-full shadow-lg top-1 left-1 bg-yellow"></div>
            </TextBubble>
          )
        }

        if (textBubbleAni1 && index === 1) {
          return (
            <TextBubble layout key={`textb-${index}`} variants={textVariants} initial="initial" animate="animate" className="relative shadow-lg">
              <Message onMouseOver={e => setMouseOvers(e)} onMouseLeave={() => setMouseInteracting(false)}>
                {parse(message.text)}
              </Message>
              <div className="absolute z-0 w-full h-full shadow-lg top-1 left-1 bg-yellow"></div>
            </TextBubble>
          )
        }

        if (textBubbleAni2 && index === 2) {
          return (
            <TextBubble layout key={`textb-${index}`} variants={textVariants} initial="initial" animate="animate" className="relative shadow-lg">
              <Message onMouseOver={e => setMouseOvers(e)} onMouseLeave={() => setMouseInteracting(false)}>
                {parse(message.text)}
              </Message>
              <div className="absolute z-0 w-full h-full shadow-lg top-1 left-1 bg-yellow"></div>
            </TextBubble>
          )
        }
      })}
    </>
  )
}

export default NoteBubble
